import React, { useState } from 'react';
import { useChangeUserPasswordMutation } from '../../store/user/user.api';
import ModalContainer from '../layout/modal-container';
import ChangePasswordModal from './change-password-modal';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { notBlank } from '../../utils/base';

const ChangePasswordForm = () => {
   const [translate] = useTranslation('common');
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [errorMsg, setErrorMsg] = useState('');
   const [success, setSuccess] = useState('');
   const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
   const [changeUserPassword, { isLoading, isSuccess, isError, error }] = useChangeUserPasswordMutation();

   const handleChangePassword = async () => {
      if (newPassword !== confirmPassword) {
         setErrorMsg('New password and confirmation do not match');
         return;
      }

      try {
         await changeUserPassword({
            newPassword: newPassword
         }).unwrap();

         setErrorMsg('');
         setSuccess('Password changed successfully!');
         setNewPassword('');
         setConfirmPassword('');
      } catch (err) {
         //
      }
   };

   const isButtonEnabled = !isLoading && notBlank(newPassword) && notBlank(confirmPassword);

   const renderError = () => {
      if (isError && error) {
         if ('data' in error && error.status === 400) {
            return (
               <p style={{ color: 'red' }}>
                  {(error.data as any).message || 'Failed to change password'}
               </p>
            );
         } else if ('status' in error) {
            return (
               <p style={{ color: 'red' }}>
                  Error {error.status}: {JSON.stringify(error.data) || 'Failed to change password'}
               </p>
            );
         } else {
            return <p style={{ color: 'red' }}>Something went wrong: {error.message}</p>;
         }
      }
      return null;
   };

   return (
      <>
         <Button variant='primary' onClick={() => setShowChangePasswordModal(true)} >
            {translate('settings.changePassword')}
         </Button>
         <ModalContainer
            modalContent={
               <ChangePasswordModal
                  newPassword={newPassword}
                  confirmPassword={confirmPassword}
                  setNewPassword={setNewPassword}
                  setConfirmPassword={setConfirmPassword}
               />
            }
            modalHeader={translate('settings.changePassword')}
            submitButtonText={translate('common:common.save')}
            show={showChangePasswordModal}
            onCancel={() => setShowChangePasswordModal(false)}
            onSubmit={() => {
               handleChangePassword()
                  .finally(() => setShowChangePasswordModal(false));
            }}
            submitButtonEnabled={isButtonEnabled}
         />
         {isSuccess && <p style={{ color: 'green' }}>{success}</p>}
         {renderError() ?? (errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>)}
      </>
   );
};

export default ChangePasswordForm;
